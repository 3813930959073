import { PlayCircleFilled, PauseCircleFilled } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { getBackingTrackPlaying } from "redux/songwriting/songwriting_selectors";

export type SongBackingTrackPlayerProps = {
  toggleBeat: () => void;
};

export const SongBackingTrackPlayer = ({
  toggleBeat,
}: SongBackingTrackPlayerProps) => {
  const backingTrackPlaying = useSelector(getBackingTrackPlaying);

  return (
    <button type="button" onClick={toggleBeat}>
      {backingTrackPlaying ? (
        <PauseCircleFilled fontSize="large" />
      ) : (
        <PlayCircleFilled fontSize="large" />
      )}
    </button>
  );
};
