import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button, { BTN_TYPE } from "components/reusable/buttons/button";
import {
  cancelAtPeriodEndSelector,
  getIsPromoSelector,
  getMemberSubscriptionEnd,
} from "redux/auth/user_selectors";
import { isButtonDisabledSelector } from "redux/ui/form/form_selectors";
import {
  getCustomerCardSelector,
  getStripeProduct,
} from "redux/entities/stripe/stripe_selectors";
import { unsubscribe } from "redux/entities/stripe/stripe_slice";
import {
  deleteAccountModal,
  openPayForSubscriptionModal,
} from "redux/ui/modal/modal_slice";
import { IRoles, ROLES } from "redux/auth/roles/roles_consts";
import { loginUser } from "redux/auth/auth_slice";
import { logout } from "redux/auth/auth_actions";
// import { FreePlanPrice } from "components/modal/choose_plan_modal/subscription_plan/free_plan_price";
import { MemberPlanPrice } from "components/modal/choose_plan_modal/subscription_plan/member_plan_price";
import { format } from "date-fns";

import { getOrganizationName } from "redux/organizations/organization_selectors";
import {
  getRoleSelector,
  accessByRoleSelector,
} from "redux/auth/roles/roles_selector";
import css from "./profile_selected_plan.module.scss";

export const ProfileSelectedPlan = () => {
  const dispatch = useDispatch();

  const cancelAtPeriodEnd = useSelector(cancelAtPeriodEndSelector);
  const isButtonDisabled = useSelector(isButtonDisabledSelector);
  const card = useSelector(getCustomerCardSelector);
  const subscriptionEnd = useSelector(getMemberSubscriptionEnd);

  const changeToMemberCallback = useCallback(() => {
    dispatch(openPayForSubscriptionModal());
  }, [dispatch]);

  const changeToFreeCallback = useCallback(() => {
    dispatch(unsubscribe());
  }, [dispatch]);

  const deleteAccount = () => {
    dispatch(deleteAccountModal());
  };

  const selectedPaymentMethod = card
    ? `You are currently using ${card.brand} (**** ${card.last4})`
    : "No payment method provided";
  const role = useSelector(getRoleSelector);
  const isPromo = useSelector(getIsPromoSelector);
  const product = useSelector(getStripeProduct);
  const endDateStr = format(new Date(subscriptionEnd || 0), "PP");
  const orgName = useSelector(getOrganizationName);
  const isMember = useSelector(
    accessByRoleSelector([ROLES.INDIVIDUAL, ROLES.FREE_USER]),
  );

  const termsOfServiceLink = new URL("https://moosiko.com/app-privacy-policy/");

  const emulateUser = async () => {
    dispatch(logout());
    dispatch(
      loginUser({
        user: { email: "", fname: "", id: 4933, lname: "" },
        // @ts-ignore
        emulatedRole: ROLES.PAID_TEACHER_ACCOUNT,
      }),
    );
  };

  const getRoleDescription = (role: IRoles | null) => {
    switch (role) {
      case ROLES.FREE_TEACHER_ACCOUNT:
        return {
          planName: `Free School Plan - ${orgName}`,
          planPrice: null,
          planDescription: (
            <div>
              Invite unlimited students, create unlimited classes to use
              Moosiko. 3 free song lessons for every student.
            </div>
          ),
        };
      case ROLES.PAID_TEACHER_ACCOUNT:
        return {
          planName: `Paid School Plan - ${orgName}`,
          planDescription: (
            <div>
              Invite unlimited students, creat unlimited classes. Students have
              full access to all lessons. Access to skill tracking, engagement
              time, and video assessment features.
            </div>
          ),
        };
      case ROLES.SOLO_TEACHER:
        return {
          planName: "Solo Teacher Plan",
          planPrice: isPromo ? (
            <div className={css.promo_plan}>"Free Promo Account"</div>
          ) : (
            <MemberPlanPrice product={product} />
          ),
          planDescription: (
            <div>
              Full access to all song lessons, song sheets, and play along
              featuers to use in your class.
            </div>
          ),
        };
      case ROLES.FREE_USER:
        return {
          planName: "Free Plan",
          planPrice: null,
          planDescription: (
            <>
              <div>
                3 free song lessons to get started with you favorites tunes or
                riffs. Track you skills and practice time.
              </div>
              <Button
                type={BTN_TYPE.SECONDARY}
                onClick={changeToMemberCallback}
                showLoader={isButtonDisabled}
              >
                Change to Member
              </Button>
            </>
          ),
        };
      case ROLES.INDIVIDUAL:
        return {
          planName: cancelAtPeriodEnd
            ? `Member Plan until ${endDateStr}`
            : `Member Plan (next cycle begins on ${endDateStr})`,
          planPrice: isPromo ? (
            <div className={css.promo_plan}>"Free Promo Account"</div>
          ) : (
            <MemberPlanPrice product={product} />
          ),
          planDescription: (
            <>
              <div>
                Full access to all song lessons, skill tracking, and song
                recommendations.
              </div>
              <Button
                type={BTN_TYPE.SECONDARY}
                onClick={changeToFreeCallback}
                showLoader={isButtonDisabled}
              >
                Change to Free
              </Button>
            </>
          ),
        };
      case ROLES.FREE_TEACHER_STUDENT:
        return {
          planName: `Free School Plan - ${orgName}`,
          planPrice: null,
          planDescription: (
            <div>
              3 free song lessons to get started with your favorites tunes or
              riffs. Track your skills and practice time.
            </div>
          ),
        };
      case ROLES.PAID_TEACHER_STUDENT:
        return {
          planName: `Paid School Plan - ${orgName}`,
          planPrice: null,
          planDescription: (
            <div>
              Full access to all song lessons, skill tracking, song
              recommendations, and video recording.
            </div>
          ),
        };
      case ROLES.ADMIN:
        return {
          planName: "Admin",
          planPrice: null,
          planDescription: (
            <>
              <div>Create and manage orgs and users.</div>
              <Button type={BTN_TYPE.SECONDARY} onClick={emulateUser}>
                Emulate User
              </Button>
            </>
          ),
        };
      case ROLES.DISTRICT_ADMINISTRATOR:
        return {
          planName: "District Plan",
          planPrice: null,
          planDescription: (
            <div>
              View data across multiple schools, teachers, and students.{" "}
            </div>
          ),
        };
      case ROLES.FREE_SONGWRITING:
        return {
          planName: "Free Songwriting Plan",
          planPrice: null,
          planDescription: (
            <>
              <div>3 Free Songs to get you started.</div>
              <Button
                type={BTN_TYPE.SECONDARY}
                onClick={changeToMemberCallback}
                showLoader={isButtonDisabled}
              >
                Change to Member
              </Button>
            </>
          ),
        };
      case ROLES.PAID_SONGWRITING:
        return {
          planName: cancelAtPeriodEnd
            ? `Songwriting Member Plan until ${endDateStr}`
            : `Songwriting Member Plan (next cycle begins on ${endDateStr})`,
          planPrice: isPromo ? (
            <div className={css.promo_plan}>"Free Promo Account"</div>
          ) : (
            <MemberPlanPrice product={product} />
          ),
          planDescription: (
            <>
              <div>Write Unlimited Songs.</div>
              <Button
                type={BTN_TYPE.SECONDARY}
                onClick={changeToFreeCallback}
                showLoader={isButtonDisabled}
              >
                Change to Free
              </Button>
            </>
          ),
        };
      default:
        return {
          planName: "Unknown Plan Name",
          planPrice: null,
          planDescription: null,
        };
    }
  };

  const userRole = getRoleDescription(role);

  return (
    <div className={css.layout}>
      <div className={css.section_title}>Your Plan</div>
      <div className={css.plan_name}>{userRole.planName}</div>
      <div className={css.price_wrapper}>{userRole.planPrice}</div>

      {!isPromo && (
        <div className={css.submit_btn_wrapper}>{userRole.planDescription}</div>
      )}

      {isMember && !isPromo && (
        <>
          <div className={css.divider} />
          <div className={css.payment_method}>Payment Method</div>
          <div className={css.payment_method_selected}>
            {selectedPaymentMethod}
          </div>
        </>
      )}
      <Button type={BTN_TYPE.SECONDARY} onClick={deleteAccount}>
        Delete Account
      </Button>
      <div className={css.terms}>
        <a
          href={termsOfServiceLink.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          Moosiko&nbsp;Privacy&nbsp;Policy
        </a>
      </div>
    </div>
  );
};
