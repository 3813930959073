/* eslint-disable no-magic-numbers */
import React, { ChangeEvent, useCallback } from "react";
import { InputSearch } from "components/reusable/input_search";
import { useSelector, useDispatch } from "react-redux";
import { getDecadesSelector } from "redux/decades/decades_selectors";
import { MenuItem, Popover, ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { usePopover } from "hooks/use_popover";
import classNames from "classnames";
import { Skill } from "components/reusable/skill";
import {
  getLessonKeys,
  getSongKeys,
} from "redux/entities/lessons/lesson_selectors";
import { getChordsLikeStringSelector } from "redux/entities/chords/chords_selectors";
import { setSongFilter } from "redux/songs_filter/song_filter_slice";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import Switch from "@material-ui/core/Switch";
import css from "./songs_tool_panel.module.scss";
import { SKILL_TYPES } from "../constants";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#9c80f4",
    },
  },
});

interface ISongsToolPanel {
  showGrid: boolean;
}

// TODO: extract to several components
export const SongsToolPanel = (props: ISongsToolPanel) => {
  const { showGrid } = props;
  const dispatch = useDispatch();
  const decades = useSelector(getDecadesSelector);

  const setSelectedDecade = useCallback(
    (data: string) => dispatch(setSongFilter({ name: "decade", data })),
    [dispatch],
  );

  const setSearch = useCallback(
    (data: string) => dispatch(setSongFilter({ name: "search", data })),
    [dispatch],
  );

  const setSelectedSkillTypes = useCallback(
    (data: string[]) => dispatch(setSongFilter({ name: "skillTypes", data })),
    [dispatch],
  );

  const setNumberOfChords = (data: string) =>
    dispatch(setSongFilter({ name: "numberOfChords", data }));

  const setSelectedLessonKeys = (data: string[]) =>
    dispatch(setSongFilter({ name: "lessonKeys", data }));

  const setSelectedSongKeys = (data: string[]) =>
    dispatch(setSongFilter({ name: "songKeys", data }));

  const setSelectedChords = (data: string[]) =>
    dispatch(setSongFilter({ name: "chords", data }));

  const setChordsExactMatch = (data: boolean) =>
    dispatch(setSongFilter({ name: "chordsExactMatch", data }));

  const {
    search,
    chords: selectedChords,
    decade: selectedDecade,
    lessonKeys: selectedLessonKeys,
    numberOfChords,
    skillTypes: selectedSkillTypes,
    songKeys: selectedSongKeys,
    chordsExactMatch,
  } = useSelector(getSongFilters);

  const onSearchChange = useCallback(
    (event) => {
      const { value } = event.target;
      setSearch(value);
    },
    [setSearch],
  );

  const [decadePopoverAnchor, openDecadePopover, closeDecadePopover] =
    usePopover();

  const handleDecade = useCallback(
    (value) => {
      setSelectedDecade(value);
      closeDecadePopover({} as React.MouseEvent);
    },
    [closeDecadePopover, setSelectedDecade],
  );

  const [skillTypePopoverAnchor, openSkillTypePopover, closeSkillTypePopover] =
    usePopover();

  const skillTypeSelectValue = () => {
    switch (selectedSkillTypes.length) {
      case 0:
        return "Skills";
      case 1:
        return selectedSkillTypes[0];
      default:
        return `${selectedSkillTypes.length} Skills`;
    }
  };

  const onSkillTypesChange = useCallback(
    (skillType) => {
      let newSelectedSkillTypes = [];
      if (selectedSkillTypes.includes(skillType)) {
        newSelectedSkillTypes = selectedSkillTypes.filter(
          (skill) => skill !== skillType,
        );
      } else {
        newSelectedSkillTypes = [...selectedSkillTypes, skillType];
      }
      setSelectedSkillTypes(newSelectedSkillTypes);
    },
    [selectedSkillTypes, setSelectedSkillTypes],
  );

  const [
    numberOfChordsPopoverAnchor,
    openNumberOfChordsPopover,
    closeNumberOfChordsPopover,
  ] = usePopover();

  const handleNumberOfChords = (value: string) => {
    setNumberOfChords(value);
    closeNumberOfChordsPopover({} as React.MouseEvent);
  };

  let numberOfChordsText = "# of Chords";
  if (numberOfChords === "1") {
    numberOfChordsText = "1 Chord";
  } else if (Number(numberOfChords) > 1) {
    numberOfChordsText = `${numberOfChords} Chords`;
  }

  const [lessonKeyPopoverAnchor, openLessonKeyPopover, closeLessonKeyPopover] =
    usePopover();

  const allLessonKeys = useSelector(getLessonKeys);
  const handleLessonKey = (value: string) => {
    let newLessonKeys = [];
    if (selectedLessonKeys.includes(value)) {
      newLessonKeys = selectedLessonKeys.filter(
        (lessonKey) => lessonKey !== value,
      );
    } else {
      newLessonKeys = [...selectedLessonKeys, value];
    }
    setSelectedLessonKeys(newLessonKeys);
  };

  const [chordsPopoverAnchor, openChordsPopover, closeChordsPopover] =
    usePopover();

  const [songKeyPopoverAnchor, openSongKeyPopover, closeSongKeyPopover] =
    usePopover();

  const handleChordsExactMatch = (event: ChangeEvent<HTMLInputElement>) => {
    setChordsExactMatch(event.target.checked);
  };

  const allSongKeys = useSelector(getSongKeys) as string[];
  const handleSongKey = (value: string) => {
    let newSongKeys = [];
    if (selectedSongKeys.includes(value)) {
      newSongKeys = selectedSongKeys.filter((songKey) => songKey !== value);
    } else {
      newSongKeys = [...selectedSongKeys, value];
    }
    setSelectedSongKeys(newSongKeys);
  };

  const handleChordsChange = (chord: string) => {
    let newChords = [];
    if (selectedChords.includes(chord)) {
      newChords = selectedChords.filter((skill) => skill !== chord);
    } else {
      newChords = [...selectedChords, chord];
    }
    setSelectedChords(newChords);
  };

  const allChords = [...new Set(useSelector(getChordsLikeStringSelector))];

  const handleClear = () => {
    setSelectedChords([]);
    setSelectedSongKeys([]);
    setSelectedLessonKeys([]);
    setNumberOfChords("");
    setSelectedSkillTypes([]);
    setSearch("");
    setSelectedDecade("");
    setChordsExactMatch(false);
  };

  const showClearButton =
    selectedChords.length ||
    selectedSongKeys.length ||
    selectedLessonKeys.length ||
    numberOfChords ||
    selectedSkillTypes.length ||
    search ||
    selectedDecade ||
    showGrid;

  // TODO: extract popovers
  return (
    <div className={css.songs_tool_panel}>
      <div className={css.search_wrapper}>
        <InputSearch
          onChange={onSearchChange}
          value={search}
          classes={{
            wrapper: css.search_input_wrapper,
          }}
          inputClasses={{
            wrapper: css.search_input_wrapper,
            input: classNames(css.search_input, {
              [css.search_input_not_empty]: search.length,
            }),
          }}
        />
      </div>
      <button
        onClick={openDecadePopover}
        type="button"
        className={classNames(css.skill_wrapper, css.show_on_mobile, {
          [css.skill_wrapper_active]: selectedDecade !== "",
        })}
      >
        <div>{selectedDecade === "" ? "Decade" : selectedDecade}</div>
      </button>
      <Popover
        anchorEl={decadePopoverAnchor}
        keepMounted
        open={Boolean(decadePopoverAnchor)}
        onClose={closeDecadePopover}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={css.popover_decade_container}>
          <button
            className={css.popover_decade_button}
            type="button"
            onClick={() => handleDecade("")}
          >
            All
          </button>
          {decades.map((decade) => (
            <button
              className={classNames(css.popover_decade_button, {
                [css.popover_decade_active]: decade.value === selectedDecade,
              })}
              key={decade.label}
              type="button"
              onClick={() => handleDecade(decade.value)}
            >
              {decade.label}
            </button>
          ))}
        </div>
      </Popover>
      <button
        type="button"
        onClick={openSkillTypePopover}
        className={classNames(css.skill_wrapper, css.show_on_mobile, {
          [css.skill_wrapper_active]: selectedSkillTypes.length,
        })}
      >
        <div>{skillTypeSelectValue()}</div>
      </button>
      <Popover
        anchorEl={skillTypePopoverAnchor}
        keepMounted
        open={Boolean(skillTypePopoverAnchor)}
        onClose={closeSkillTypePopover}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={css.popover_skillType_container}>
          {SKILL_TYPES.map((skillType) => (
            <MenuItem
              onClick={() => onSkillTypesChange(skillType)}
              key={skillType}
              value={skillType}
            >
              <ThemeProvider theme={theme}>
                <Checkbox
                  checked={selectedSkillTypes.includes(skillType)}
                  value={selectedSkillTypes.includes(skillType)}
                />
                {skillType}
              </ThemeProvider>
            </MenuItem>
          ))}
        </div>
      </Popover>
      <button
        onClick={openNumberOfChordsPopover}
        type="button"
        className={classNames(css.skill_wrapper, {
          [css.skill_wrapper_active]: numberOfChords,
        })}
      >
        <div>{numberOfChordsText}</div>
      </button>
      <Popover
        anchorEl={numberOfChordsPopoverAnchor}
        keepMounted
        open={Boolean(numberOfChordsPopoverAnchor)}
        onClose={closeNumberOfChordsPopover}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={css.popover_decade_container}>
          {["", "2", "3", "4", "5", "6+"].map((numberOfChordsOption) => (
            <button
              className={classNames(css.popover_decade_button, {
                [css.number_of_chords_active]:
                  numberOfChords && numberOfChords === numberOfChordsOption,
              })}
              key={numberOfChordsOption}
              type="button"
              onClick={() => handleNumberOfChords(numberOfChordsOption)}
            >
              {numberOfChordsOption || "All"}
            </button>
          ))}
        </div>
      </Popover>
      <button
        onClick={openChordsPopover}
        type="button"
        className={classNames(css.skill_wrapper, {
          [css.skill_wrapper_active]: selectedChords.length,
        })}
      >
        <div>Chords</div>
      </button>
      <Popover
        anchorEl={chordsPopoverAnchor}
        keepMounted
        open={Boolean(chordsPopoverAnchor)}
        onClose={closeChordsPopover}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={css.exact_match}>
          Exact Match
          <Switch
            checked={chordsExactMatch}
            onChange={handleChordsExactMatch}
            className={css.exact_match_switch}
          />
        </div>
        <div className={css.lesson_key_select}>
          {allChords.map((chord) => {
            const active = selectedChords.includes(chord);
            return (
              <Skill
                bgColor={active ? "#29BB9C" : "#D8D8D8"}
                key={chord}
                skillType={chord}
                clickable
                onClick={() => {
                  handleChordsChange(chord);
                }}
              >
                {chord}
              </Skill>
            );
          })}
        </div>
      </Popover>
      <button
        onClick={openLessonKeyPopover}
        type="button"
        className={classNames(css.skill_wrapper, {
          [css.skill_wrapper_active]: selectedLessonKeys.length,
        })}
      >
        <div>Lesson Key</div>
      </button>
      <Popover
        anchorEl={lessonKeyPopoverAnchor}
        keepMounted
        open={Boolean(lessonKeyPopoverAnchor)}
        onClose={closeLessonKeyPopover}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={css.lesson_key_select}>
          {allLessonKeys.map((currentLessonKey) => {
            const active = selectedLessonKeys.includes(currentLessonKey);
            return (
              <Skill
                bgColor={active ? "#29BB9C" : "#D8D8D8"}
                key={currentLessonKey}
                skillType={currentLessonKey}
                clickable
                onClick={() => {
                  handleLessonKey(currentLessonKey);
                }}
              >
                {currentLessonKey}
              </Skill>
            );
          })}
        </div>
      </Popover>
      <button
        onClick={openSongKeyPopover}
        type="button"
        className={classNames(css.skill_wrapper, {
          [css.skill_wrapper_active]: selectedSongKeys.length,
        })}
      >
        <div>Song Key</div>
      </button>
      <Popover
        anchorEl={songKeyPopoverAnchor}
        keepMounted
        open={Boolean(songKeyPopoverAnchor)}
        onClose={closeSongKeyPopover}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={css.song_key_select}>
          {allSongKeys.map((currentSongKey) => {
            const active = selectedSongKeys.includes(currentSongKey);
            return (
              <Skill
                bgColor={active ? "#29BB9C" : "#D8D8D8"}
                key={currentSongKey}
                skillType={currentSongKey}
                clickable
                onClick={() => {
                  handleSongKey(currentSongKey);
                }}
              >
                {currentSongKey}
              </Skill>
            );
          })}
        </div>
      </Popover>

      <button
        onClick={handleClear}
        type="button"
        className={classNames(css.clear_button, {
          [css.display_none]: !showClearButton,
        })}
      >
        Clear
      </button>
    </div>
  );
};

SongsToolPanel.propTypes = {};
SongsToolPanel.defaultProps = {};
