import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { IPermissions } from "redux/auth/permissions/permissions_consts";

interface IWithAccessRoute {
  component: React.ElementType;
  path: string;
  exact?: boolean;
  permission: IPermissions;
  role?: string;
}

export const WithAccessRoute = ({
  component: Component,
  path,
  exact = false,
  permission,
  role,
}: IWithAccessRoute) => {
  const userRole = useSelector(getRoleSelector);
  let roleAccess = true;

  if (role) {
    roleAccess = userRole === role && role !== null;
  }

  const access = useSelector(accessByPermissionSelector(permission));
  return (
    <Route
      path={path}
      exact={exact}
      render={(props: unknown) => {
        if (access && roleAccess) {
          // @ts-ignore
          return <Component {...props} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
};
