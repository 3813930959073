import { Box, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { SongBuilderMobileMetadataMenu } from "../songbuilder_mobile_metadata/songbuilder_mobile_metadata_menu";

interface ISongBuilderMoobileHeader {
  returnHandler: () => void;
}

export const SongBuilderMobileHeader = ({
  returnHandler,
}: ISongBuilderMoobileHeader) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr"
      alignItems="center"
      position="sticky"
      style={{
        top: 0,
        zIndex: 999,
        backgroundColor: "#fff",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={returnHandler}
        style={{ cursor: "pointer" }}
        height="45px"
      >
        <IconButton aria-label="back">
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <SongBuilderMobileMetadataMenu />
    </Box>
  );
};
