import cn from "classnames";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { format } from "date-fns/esm";
import { Link } from "react-router-dom";
import { ICourseStats } from "redux/courses/courses_api";
import { IStudentWithSkills } from "types/models/student_with_skills";
import css from "./student_row_preview.module.scss";

interface IStudentRowPreview {
  student: IStudentWithSkills;
  studentCsvData: ICourseStats;
}

export const StudentRowPreview = (props: IStudentRowPreview) => {
  const { student, studentCsvData } = props;

  const { skillsLearnedPercentage, last_sign_in_at, id } = student;

  const getStatus = (lastSignUpAt: string) => {
    if (lastSignUpAt) {
      return `Last active ${format(new Date(lastSignUpAt), "MMM do h:mma")}`;
    }
    return <div className={css.invite_send}>OK to Login </div>;
  };
  const { courseId } = student;

  return (
    <Link className={cn(css.tr)} to={`/student/${courseId}/${id}`}>
      <div className={css.row_item}>
        {`${studentCsvData?.first_name} ${studentCsvData?.last_name}`}
      </div>
      <div className={cn(css.row_item)}>
        <span className={cn(css.skills_count)}>
          {studentCsvData?.skills_number}
        </span>
        {skillsLearnedPercentage}%
      </div>
      <div className={cn(css.row_item)}>
        <span className={cn(css.skills_count)}>
          {studentCsvData?.lessons_number}
        </span>
      </div>
      <div className={cn(css.time_cell, css.row_item)}>
        {studentCsvData?.practice_time_mins}
      </div>
      <div className={cn(css.last_login, css.row_item)}>
        {getStatus(`${last_sign_in_at || ""}`)}
        <div className={cn(css.open_arrow)}>
          <KeyboardArrowRightIcon fontSize="large" />
        </div>
      </div>
    </Link>
  );
};
