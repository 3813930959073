import { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, TextField, Button, Box } from "@material-ui/core";
import { IOrgWithStudents } from "redux/org_management/org_management_api";
import { removeExpiredStudents } from "redux/organizations/organization_slice";
import { updateOrganization } from "../../../redux/org_management/org_management_slice";
import css from "../org_management.module.scss";

interface IOrganizationCard {
  org: IOrgWithStudents;
}

export const OrganizationCard = (props: IOrganizationCard) => {
  const { org } = props;
  const [name, setName] = useState(org.name);
  const [orgSeats, setOrgSeats] = useState(org.max_seats);

  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(updateOrganization({ name, max_seats: orgSeats, id: org.id }));
  };

  const handleRemoveExpiredStudents = () => {
    dispatch(removeExpiredStudents({ orgId: org.id }));
  };

  return (
    <Card className={css.org_card} key={org.id}>
      <h2>
        <span className={css.card_text}>Name: </span>
        <TextField
          onChange={(event) => setName(event.target.value)}
          value={name}
        />
      </h2>
      <div>
        <span className={css.card_text}>Max students: </span>
        <TextField
          type="number"
          onChange={(event) => setOrgSeats(Number(event.target.value))}
          value={orgSeats}
        />
      </div>
      <div>
        <span className={css.card_text}>Students: {org.students}</span>
      </div>
      <div>
        <span className={css.card_text}>Email: {org.teacher?.uid}</span>
      </div>
      <div>
        <span className={css.card_text}>
          Name: {org.teacher?.fname} {org.teacher?.lname}
        </span>
      </div>

      <Button
        type="button"
        onClick={handleRemoveExpiredStudents}
        className={css.org_button}
        color="primary"
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <div>Remove Students</div>
          <div>From Expired Courses</div>
        </Box>
      </Button>
      <Button
        type="button"
        onClick={handleSubmit}
        className={css.org_button}
        color="primary"
      >
        Submit
      </Button>
    </Card>
  );
};
