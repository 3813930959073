import React, { CSSProperties, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCustomSelectedSong,
  updateCustomSong,
} from "redux/songwriting/songwriting_slice";
import { Box, Button } from "@material-ui/core";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import { SongKeyPopover } from "../songbuilder_header/custom_song_settings/song_metadata/song_key/song_key_popover";
import { SongTempoPopover } from "../songbuilder_header/custom_song_settings/song_metadata/song_tempo/song_tempo_popover";
import { SongBackingTrackGroup } from "../songbuilder_header/custom_song_settings/song_metadata/song_backing_track_group/song_backing_track_group";

export const SongBuilderMobileMetadataMenu = () => {
  const dispatch = useDispatch();
  const [anchorEl_key, setAnchorEl_key] = useState<HTMLButtonElement | null>(
    null,
  );
  const [anchorEl_tempo, setAnchorEl_tempo] =
    useState<HTMLButtonElement | null>(null);
  const { custom_song_key, tempo } = useSelector(getCurrentCustomSong);

  const handleOpen_key = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl_key(event.currentTarget);
  };

  const handleClose_key = () => {
    setAnchorEl_key(null);
  };

  const [sliderValue, setSliderValue] = useState<number>(tempo);

  const handleOpen_tempo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl_tempo(event.currentTarget);
    setSliderValue(tempo);
  };

  const handleClose_tempo = () => {
    setAnchorEl_tempo(null);
    if (sliderValue !== tempo) {
      dispatch(updateCustomSong({ tempo: sliderValue }));
      dispatch(updateCustomSelectedSong({ tempo: sliderValue }));
    }
  };

  const counterRef = useRef<number>(0);

  const buttonContentStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const buttonContentValue: CSSProperties = {
    fontWeight: 800,
    lineHeight: "0.75",
    paddingTop: "5px",
  };

  return (
    <Box
      style={{
        backgroundColor: "#F1F1F1",
        fontFamily: "Raleway",
        borderRadius: "25px",
        margin: "5px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "0 10px",
      }}
    >
      <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          handleOpen_key(event);
        }}
        style={{ textTransform: "none" }}
      >
        <Box style={buttonContentStyle}>
          <Box style={buttonContentValue}>{custom_song_key}</Box>
          <Box>Key</Box>
        </Box>
      </Button>

      <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          handleOpen_tempo(event);
        }}
        style={{ textTransform: "none" }}
      >
        <Box style={buttonContentStyle}>
          <Box style={buttonContentValue}>{sliderValue}</Box>
          <Box>Tempo</Box>
        </Box>
      </Button>

      <SongBackingTrackGroup counterRef={counterRef} />

      <SongKeyPopover anchorEl={anchorEl_key} handleClose={handleClose_key} />
      <SongTempoPopover
        anchorEl={anchorEl_tempo}
        handleClose={handleClose_tempo}
        sliderValue={sliderValue}
        setSliderValue={setSliderValue}
      />
    </Box>
  );
};
