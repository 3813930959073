import { useRef } from "react";

import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { ROLES } from "redux/auth/roles/roles_consts";
import { useSelector } from "react-redux";
import { DEV } from "util/vars";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import { SongKey } from "./song_metadata/song_key/song_key";
import { SongMeter } from "./song_metadata/song_meter/song_meter";
import { SongTempo } from "./song_metadata/song_tempo/song_tempo";
import css from "./custom_song_settings.module.scss";
import GenerateVoice from "../songbuilder_generate_voice";
import { SongBackingTrackGroup } from "./song_metadata/song_backing_track_group/song_backing_track_group";

export const CustomSongSettings = () => {
  const role = useSelector(getRoleSelector);
  const currentSong = useSelector(getCurrentCustomSong);
  const counterRef = useRef<number>(0);

  return (
    <div className={css.settings}>
      <SongKey />
      <SongMeter />
      <SongTempo />
      {currentSong.time_signature === "4/4" ? (
        <SongBackingTrackGroup counterRef={counterRef} />
      ) : null}
      {(role === ROLES.ADMIN || DEV) && <GenerateVoice />}
    </div>
  );
};
