import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBackingTrackPlaying,
  getCurrentCustomSong,
} from "redux/songwriting/songwriting_selectors";
import { Box } from "@material-ui/core";
import * as Tone from "tone";
import {
  setBackingTrackPlaying,
  toggleBackingTrackPlaying,
} from "redux/songwriting/songwriting_slice";
import { SongBackingTrack } from "../song_backing_track/song_backing_track";
import { SongBackingTrackPlayer } from "../song_backing_track_player/song_backing_track_player";

import hiHatHit from "./song_backing_track_assets/HHats-CL-V09.wav";
import openHiHatHit from "./song_backing_track_assets/HHats-OP-V05.wav";
import kickHit from "./song_backing_track_assets/Kick-V05.wav";
import snareHit from "./song_backing_track_assets/RD_S_9.wav";
import rideHit from "./song_backing_track_assets/Ride-V03.wav";
import crashHit from "./song_backing_track_assets/Crash-V01.wav";
import highTomHit from "./song_backing_track_assets/TOM10-V07.wav";
import highTomFlam from "./song_backing_track_assets/FLAM10-V04.wav";
import floorTomHit from "./song_backing_track_assets/lowTom.wav";
import midTomHit from "./song_backing_track_assets/TOM10-V05-StarClassic-10x10.wav";
import shakerHit from "./song_backing_track_assets/RD_P_ES_6.wav";
import tambourineHit from "./song_backing_track_assets/Tambourine_01_728.wav";

export interface SongBackingTrackGroupProps {
  counterRef: { current: any };
}

export const SongBackingTrackGroup = ({
  counterRef,
}: SongBackingTrackGroupProps) => {
  interface DrumSetRef {
    hh: Tone.Sampler;
    oh: Tone.Sampler;
    sn: Tone.Sampler;
    kd: Tone.Sampler;
    rc: Tone.Sampler;
    cc: Tone.Sampler;
    ft: Tone.Sampler;
    ht: Tone.Sampler;
    htf: Tone.Sampler;
    mt: Tone.Sampler;
    sh: Tone.Sampler;
    tm: Tone.Sampler;
  }

  const [loop, setLoop] = useState<Tone.Loop | undefined>(undefined);
  const [backBeat, setBackBeat] = useState("Pop");
  const backbeatRef = useRef("Pop");
  const magHalf: number = 0.5;
  const mag1: number = 1;
  const mag2: number = 2;
  const mag3: number = 3;
  const mag4: number = 4;
  const mag32: number = 32;

  const currentSong = useSelector(getCurrentCustomSong);
  const backingTrackPlaying = useSelector(getBackingTrackPlaying);
  const dispatch = useDispatch();

  const drumSetRef = useRef<DrumSetRef | undefined>({
    hh: new Tone.Sampler({
      urls: { C2: hiHatHit },
      volume: -3,
    }).toDestination(),
    oh: new Tone.Sampler({
      urls: { C2: openHiHatHit },
      volume: -5,
    }).toDestination(),
    sn: new Tone.Sampler({
      urls: { C2: snareHit },
      volume: -7,
    }).chain(
      new Tone.Reverb({
        decay: mag2,
        wet: 0.5,
      }),
      Tone.Destination,
    ),
    tm: new Tone.Sampler({
      urls: { C2: tambourineHit },
      volume: -7,
    }).toDestination(),
    kd: new Tone.Sampler({
      urls: { C2: kickHit },
      volume: 4,
    }).chain(
      new Tone.Reverb({
        decay: mag1,
        wet: 0.5,
      }),
      Tone.Destination,
    ),
    rc: new Tone.Sampler({
      urls: { C2: rideHit },
      volume: 3,
    }).toDestination(),
    cc: new Tone.Sampler({
      urls: { C2: crashHit },
    }).toDestination(),
    ft: new Tone.Sampler({
      urls: { C2: floorTomHit },
      volume: -10,
    }).toDestination(),
    ht: new Tone.Sampler({
      urls: { C2: highTomHit },
      volume: -10,
    }).toDestination(),
    htf: new Tone.Sampler({
      urls: { C2: highTomFlam },
      volume: -10,
    }).toDestination(),
    mt: new Tone.Sampler({
      urls: { C2: midTomHit },
      volume: -5,
    }).toDestination(),
    sh: new Tone.Sampler({
      urls: { C2: shakerHit },
      volume: -12,
    }).toDestination(),
  });
  const drumRef = useRef({
    pop: {
      hh: [
        1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0,
        1, 0, 1, 0, 1, 0, 0, 0,
      ],
      kd: [
        1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0,
        1, 0, 0, 0, 0, 0, 0, 0,
      ],
      sn: [
        0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0,
        0, 0, 0, 0, 1, 0, 0, 0,
      ],
      oh: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 1, 0,
      ],
    },
    rock: {
      hh: [
        1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0,
        1, 0, 1, 0, 1, 0, 0, 0,
      ],
      kd: [
        1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0,
        1, 0, 1, 0, 0, 0, 0, 0,
      ],
      sn: [
        0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0,
        0, 0, 0, 0, 1, 0, 0, 0,
      ],
      ft: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 1,
      ],
      ht: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 1, 0,
      ],
      oh: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 1, 0, 0,
      ],
    },
    blues: {
      rc: [
        1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1,
        1, 0, 0, 1, 1, 0, 0, 1,
      ],
      kd: [
        1, 0, 0, 1, 0, 0, 0, 1, 1, 0, 0, 1, 0, 0, 0, 1, 1, 0, 0, 1, 0, 0, 0, 1,
        1, 0, 0, 1, 0, 0, 0, 1,
      ],
      sn: [
        0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0,
        0, 0, 0, 0, 1, 0, 0, 0,
      ],
    },
    country: {
      hh: [
        0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0,
        0, 0, 1, 0, 0, 0, 1, 0,
      ],
      kd: [
        1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0,
        1, 0, 0, 0, 1, 0, 0, 0,
      ],
      sn: [
        0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0,
        0, 1, 1, 0, 0, 1, 1, 0,
      ],
    },
    folk: {
      sh: [
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1,
      ],
      kd: [
        1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0,
        1, 0, 0, 0, 1, 0, 0, 0,
      ],
      tm: [
        0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0,
        0, 0, 0, 0, 1, 0, 0, 0,
      ],
      ht: [
        0, 0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
      ],
      htf: [
        1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
      ],
      mt: [
        0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
      ],
      ft: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 1, 0, 0, 1,
        0, 1, 0, 0, 1, 0, 1, 1,
      ],
    },
  });

  const selectBeat = (e: any) => {
    const val: string = e.target.value;
    setBackBeat(val);
    if (loop) {
      loop.stop();
      dispatch(setBackingTrackPlaying(false));
      counterRef.current = 0;
    }
    backbeatRef.current = val;
  };

  const toggleBeat = async () => {
    if (loop) {
      if (!backingTrackPlaying) {
        loop.start();
      } else {
        loop.stop();
        counterRef.current = 0;
      }
    }
    dispatch(toggleBackingTrackPlaying());
  };

  const play = useCallback(
    (time: any) => {
      const currentBeat = backbeatRef.current.toLocaleLowerCase();
      if (currentBeat === "country" && drumSetRef.current) {
        drumSetRef.current.sn.volume.value = -20;
        drumSetRef.current.hh.volume.value = -10;
      } else if (currentBeat !== "country" && drumSetRef.current) {
        drumSetRef.current.sn.volume.value = -7;
        drumSetRef.current.hh.volume.value = -3;
      }
      // @ts-ignore
      Object.keys(drumRef.current[currentBeat]).forEach((key) => {
        // @ts-ignore
        drumRef.current[currentBeat][key].forEach((beat: number, i: number) => {
          if (drumSetRef.current && beat > 0 && i === counterRef.current) {
            if (currentBeat === "country") {
              if (key === "sn") {
                if (
                  (i / mag4) % mag4 !== 0 &&
                  (i / mag2) % mag2 !== 0 &&
                  i !== 0
                ) {
                  drumSetRef.current[key].volume.value = -20;
                  drumSetRef.current[key].triggerAttackRelease(
                    "C2",
                    "8n",
                    time,
                    magHalf,
                  );
                } else {
                  drumSetRef.current[key].volume.value = -25;
                  drumSetRef.current[key].triggerAttackRelease(
                    "C2",
                    "8n",
                    time,
                    magHalf * -1,
                  );
                }
              }
            }
            if (currentBeat === "folk") {
              if (key === "sh") {
                if ((i + 1) % mag4 !== 0 && i !== 0) {
                  drumSetRef.current[key].volume.value = -18;
                  drumSetRef.current[key].triggerAttackRelease(
                    "C2",
                    "8n",
                    time,
                    magHalf,
                  );
                } else {
                  drumSetRef.current[key].volume.value = -20;
                  drumSetRef.current[key].triggerAttackRelease(
                    "C2",
                    "8n",
                    time,
                    magHalf * -1,
                  );
                }
              }
            }
            if (key === "cc") {
              drumSetRef.current[key].triggerAttackRelease("C2", "1m", time);
            }
            if (key === "oh") {
              if (currentBeat === "rock") {
                drumSetRef.current[key].triggerAttackRelease("C2", "4n", time);
              }
              drumSetRef.current[key].triggerAttackRelease("C2", "8n", time);
            } else {
              // @ts-ignore
              drumSetRef.current[key].triggerAttackRelease("C2", mag3, time);
            }
          }
        });
      });
    },
    [counterRef],
  );

  const playSong = useCallback(
    (time: any) => {
      play(time);
      counterRef.current = (counterRef.current + mag1) % mag32;
    },
    [play, counterRef],
  );

  useEffect(() => {
    if (Tone.Transport.bpm.value !== Number(currentSong.tempo)) {
      Tone.Transport.stop();
      Tone.Transport.bpm.value = Number(currentSong.tempo);
      Tone.Transport.start();
    }
    if (loop === undefined) {
      setLoop(new Tone.Loop(playSong, "16n"));
      Tone.start();
      Tone.loaded();
      Tone.Transport.timeSignature = mag4 / mag4;
      Tone.Transport.start();
    }
    return () => {
      loop?.dispose();
      dispatch(setBackingTrackPlaying(false));
      counterRef.current = 0;
    };
  }, [loop, playSong, currentSong.tempo, counterRef, setLoop, dispatch]);

  useEffect(() => {
    if (!backingTrackPlaying && loop) {
      loop.stop();
      counterRef.current = 0;
    }
  }, [backingTrackPlaying, loop, counterRef]);

  return (
    <Box display="flex">
      <SongBackingTrack selectedBeat={backBeat} selectBeat={selectBeat} />
      <SongBackingTrackPlayer toggleBeat={toggleBeat} />
    </Box>
  );
};
